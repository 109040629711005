exports.shownTags = [
  'breakfast',
  'lunch',
  'dinner',
  'dessert',

  'american',
  'asian',
  'indian',
  'mexican',
];
